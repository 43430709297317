// Layout ========================================================================

export const COMMUNITY_LOGO_URLS = {
  default: '/images/logo.svg',
  'Клуб Лидеров Москва': '/images/communities/leaders-club.png',
};

// Theme =========================================================================

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
};
export const lightTheme = (theme) => theme.palette.mode === 'light';
export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

export const MAX_RESIDENT_JOBS = 5;
export const MAX_RESIDENT_EDUCATIONS = 5;
export const MAX_RESIDENT_PROJECTS = 5;

export const MAX_RESIDENT_STATUS_COUNT = 10;

export const MAX_EXTRA_ACTUAL_REQUESTS = 2;

export const DEFAULT_AVATAR = '/images/communities/default-community-avatar.png';
export const DEFAULT_AVATAR_BACKGROUND = 'linear-gradient(0deg,var(--primary-8, rgba(57, 78, 202, 0.08)) 0%,var(--primary-8, rgba(57, 78, 202, 0.08)) 100%),var(--background-default, #fff)'

export const DEFAULT_PALETTE_COLORS = [
  '#394ECA',
  '#2D77FC',
  '#8E33FF',
  '#FE3CA4',
  '#FE3C3C',
  '#FF6B00',
  '#FFAB00',
  '#22C55E',
  '#22BBC5',
  '#77798A'
];

export const COMMUNITY_PAGE_ALPHA_MIN = 0.1;
export const COMMUNITY_PAGE_ALPHA_MAX = 0.16;
