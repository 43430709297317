import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import styles from './LinkInvite.module.scss';
import { copyToClipboard } from '../../../../lib/clipboard';
import { useSelector } from 'react-redux';
import { CopiedSnack } from './CopiedSnack/CopiedSnack';

export const LinkInvite = () => {
  const { community } = useSelector(state => state.community);
  const navigate = useNavigate();
  const url = `${process.env.REACT_APP_API_URL}club-invitation/${community.id}`;
  const [success, setSuccess] = useState();

  const getDisplayUrl = () => {
    if (url.length < 41) return url;
    return url.slice(0, 38) + '...';
  };

  const displayUrl = getDisplayUrl();

  const closeModal = () => {
    navigate('/club/');
  };

  const copy = () => {
    copyToClipboard(url);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  return (
    <>
      <Dialog open={true} onClose={closeModal} className="wide-dialog">
        <DialogTitle>
          Пригласить по ссылке
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: '500px', overflowX: 'hidden' }} className={styles.invite}>
          <div className={styles.description}>
            Участники вашего сообщества смогут присоединиться к платформе по ссылке ниже
          </div>
          <div className={styles.link} onClick={copy}>
            <div className={styles.url}>
              {displayUrl}
            </div>
            <div className={styles.button}>
              <button>Скопировать</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {success && <CopiedSnack onClose={() => setSuccess(false)} />}
    </>
  );
};
