import { useSelector } from 'react-redux';
import styles from './Logo.module.scss';
import { COMMUNITY_LOGO_URLS } from '@data/constants';
import { Link } from 'react-router-dom';

export const Logo = () => {
  const community = useSelector((state) => state.community.community);

  const logoUrl =
    COMMUNITY_LOGO_URLS[community?.title] || COMMUNITY_LOGO_URLS.default;

  return (
    <Link to="/" className={styles.logo}>
      <img src={logoUrl} alt="Community logo" />
    </Link>
  );
};
