import { ClubRoot } from '../../features/club/components/ClubRoot/ClubRoot';
import { Page } from '../../layouts/Page/Page';

import { Outlet } from 'react-router-dom';

export const ClubPage = () => {
  return (
    <Page>
      <ClubRoot />
      <Outlet />
    </Page>
  );
};
