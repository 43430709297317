export const validatePageForm = data => {
  const { title, slogan, website, telegram, about } = data;
  const errors = {};
  if (!title) {
    errors.title = 'Введите название'
  } else if (title.length < 2 || title.length > 48) {
    errors.title = 'От 2 до 48 символов';
  }
  if (slogan) {
    if (slogan.length < 2 || slogan.length > 80) {
      errors.slogan = 'От 2 до 80 символов';
    }
  }
  if (website) {
    if (!/^(https?:\/\/)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9()]{1,6}([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i.test(
      website
    ) || website.length > 78) {
      errors.website = 'Введите корректную ссылку';
    }
  }
  if (telegram) {
    if (!/^@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/.test(telegram)) {
      errors.telegram = 'От 5 до 32 символов';
    }
  }
  if (about) {
    if (about.length < 15 || about.length > 2000) {
      errors.about = 'От 15 до 2000 символов';
    }
  }
  return errors;
};
