import { useDispatch, useSelector } from 'react-redux';
import styles from './CommunityMembers.module.scss';
import { useEffect, useState } from 'react';
import { deleteMemberFromUnsaved, findCards, updateMemberInUnsaved, updateUnsavedData } from '../../store/community-page.slice';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import { Add } from '@mui/icons-material';
import { MemberModal } from './MemberModal/MemberModal';
import { createPortal } from 'react-dom';
import { Member } from './Member/Member';
import { startProgress } from '../../../../store/common';

export const CommunityMembers = ({ editable }) => {
  const dispatch = useDispatch();
  const [showNew, setShowNew] = useState(false);
  const { team } = useSelector(state => state.communityPage[editable ? 'unsavedData' : 'data']);
  const [editMember, setEditMember] = useState(null);

  const memberAdded = data => {
    dispatch(updateUnsavedData({
      team: [...(team || []), data]
    }));
    setShowNew(false);
  };

  const doEditMember = (member) => {
    setEditMember(member);
  };

  const deleteMember = (member) => {
    dispatch(deleteMemberFromUnsaved(member.cardId))
  };

  const saveMember = (data) => {
    dispatch(startProgress());
    dispatch(updateMemberInUnsaved(editMember, data));
    setEditMember(null);
  };

  const teamIds = team.map(member => member.cardId);

  return <div className={styles.members}>
    <div className={styles.list}>
      {team.map(member => (
        <Member name={`${member.firstName} ${member.lastName}`} avatar={member.avatar} description={member.description} telegram={member.telegram} onEdit={() => doEditMember(member)} onDelete={() => deleteMember(member)} showTelegram={member.showTelegram} editable={editable} />
      ))}
    </div>
    {editable && (
      <AppButton startIcon={<Add />} color="secondary" onClick={() => setShowNew(true)}>Добавить еще</AppButton>
    )}
    {showNew && createPortal(<MemberModal exclude={teamIds} onClose={() => setShowNew(false)} onAdd={memberAdded} />, document.getElementById('overlay'))}
    {editMember && createPortal(<MemberModal exclude={teamIds} member={editMember} onClose={() => setEditMember(null)} onAdd={saveMember} />, document.getElementById('overlay'))}
  </div>
};
