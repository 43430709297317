import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  IconButton,
} from '@mui/material';
import styles from './AddResidentModal.module.scss';
import { Close } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';
import { Link, useNavigate } from 'react-router-dom';
import { ModalSectionHeader } from '../../../../components/ui/ModalSectionHeader/ModalSectionHeader';
import { useRef, useState } from 'react';
import { Field } from '@ui/Field/Field';
import { TextInput } from '@ui/TextInput/TextInput';
import { CitySelect } from '@ui/CitySelect/CitySelect';
import { DateInput } from '@ui/DateInput/DateInput';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import { FieldError } from '../../../../components/ui/FieldError/FieldError';
import { api } from '@lib/api';
import { useDispatch } from 'react-redux';
import { fetchInitCards, fetchManagement } from '../../store/club';
import { updateOptions } from '../../../auth/store/auth';
import { CloseConfirmation } from '@ui/CloseConfirmation/CloseConfirmation';
import { SuccessModal } from '../../../../components/ui/SuccessModal/SuccessModal';

export const AddResidentModal = () => {
  const buttonRef = useRef();
  const dispatch = useDispatch();
  const [formChanged, setFormChanged] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [, setIsFormValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [createdCardId, setCreatedCardId] = useState(null);

  const navigate = useNavigate();

  const addResident = async () => {
    buttonRef.current.click();
  };

  const back = () => {
    navigate('/club/');
  };

  const closeModal = () => {
    if (!formChanged) {
      back();
      return;
    }
    setShowCloseConfirmation(true);
  };

  const validateForm = (values) => {
    setFormChanged(true);
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Введите имя';
    } else if (!/[а-яА-Я]+/i.test(values.firstName)) {
      errors.firstName = 'Введено некорректное имя';
    }
    if (!values.lastName) {
      errors.lastName = 'Введите фамилию';
    } else if (!/[а-яА-Я]+/i.test(values.lastName)) {
      errors.lastName = 'Введена некорректная фамилия';
    }
    if (
      values.bdate &&
      values.bdate !== '__.__.____' &&
      !/\d{2}\.\d{2}\.\d{4}/i.test(values.bdate)
    ) {
      errors.bdate = 'Введена некорректная дата';
    }
    if (!values.phone) {
      errors.phone = 'Необходимо ввести номер телефона';
    } else if (
      values.phone !== '+7 (   )    -  -  ' &&
      !/\+\d\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/i.test(values.phone)
    ) {
      errors.phone = 'Введен некорректный номер';
    }
    if (!values.email) {
      errors.email = 'Необходимо ввести почту';
    } else if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Введен некорректный адрес';
    }
    setIsFormValid(!Object.keys(errors).length);
    return errors;
  };

  const submitForm = async (values, { setSubmitting }) => {
    const personal = {};
    if (values.bdate) {
      personal.bdate = values.bdate;
    }
    if (values.city) {
      personal.city = values.city;
    }
    const data = {
      statuses: [],
      actualRequests: [],
      disabledEducationsIds: [],
      disabledJobsIds: [],
      common: {
        about: {
          firstName: values.firstName,
          lastName: values.lastName,
          position: '',
          company: '',
          spheres: [],
          actualRequest: '',
        },
        contacts: {
          phones: values.phone ? [values.phone] : [],
          emails: values.email ? [values.email] : [],
          telegrams: values.telegram ? [values.telegram] : [],
        },
        personal,
        jobs: [],
        educations: [],
        projects: [],
      },
      resources: {
        spheres: {
          regular: [],
          other: [],
        },
        competences: {
          regular: [],
          other: [],
        },
        lobbies: [],
      },
    };
    if (data?.common?.contacts?.telegrams?.length) {
      data.common.contacts.telegrams[0] = `@${data.common.contacts.telegrams[0]}`;
    }
    const { data: response } = await api.post(`/dashboard/club/cards/`, data);
    setSubmitting(false);
    dispatch(fetchInitCards());
    dispatch(updateOptions());
    dispatch(fetchManagement());
    setCreatedCardId(response.cardId);
    if (response.isUserExists) {
      navigate(`/club/invite-existing/${response.cardId}/`);
    } else {
      setIsSuccess(true);
    }
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
    addResident();
  };

  const returnToResidents = () => {
    navigate(`/club/`);
  };

  return (
    <>
      <Dialog open={true} onClose={closeModal} className="wide-dialog">
        <DialogTitle>
          Добавление резидента
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ width: '472px', overflowX: 'hidden' }}
          className="paddingless"
        >
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              city: '',
              bdate: '',
              email: '',
              phone: '',
              telegram: '',
            }}
            validate={validateForm}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form className={styles.add} onSubmit={handleSubmit}>
                <div className={styles.section}>
                  <ModalSectionHeader title="О резиденте" />
                  <Field label="Имя">
                    <FormControl fullWidth>
                      <TextInput
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={touched.firstName && errors.firstName}
                      />
                    </FormControl>
                    {errors.firstName && touched.firstName && (
                      <FieldError>{errors.firstName}</FieldError>
                    )}
                  </Field>
                  <Field label="Фамилия">
                    <FormControl fullWidth>
                      <TextInput
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={errors.lastName && touched.lastName}
                      />
                    </FormControl>
                    {errors.lastName && touched.lastName && (
                      <FieldError>{errors.lastName}</FieldError>
                    )}
                  </Field>
                  <Field label="Город проживания">
                    <CitySelect
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      isNative
                    />
                  </Field>
                  <Field label="День рождения">
                    <div className={styles.bdate}>
                      <DateInput
                        name="bdate"
                        max="current"
                        onChange={(e) => {
                          const value = e.target.value || '';
                          setFieldValue('bdate', value);
                        }}
                        onBlur={handleBlur}
                        value={values.bdate}
                        error={errors.bdate && touched.bdate}
                      />
                      {errors.bdate && touched.bdate && (
                        <FieldError>{errors.bdate}</FieldError>
                      )}
                    </div>
                  </Field>
                </div>
                <div className={styles.section}>
                  <ModalSectionHeader title="Контакты" />
                  <Field label="Телефон">
                    <FormControl fullWidth>
                      <InputMask
                        name="phone"
                        mask="+7 (999) 999-99-99"
                        onChange={(e) => {
                          const value = e.target.value || '';
                          setFieldValue('phone', value);
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                        disabled={false}
                        maskChar=" "
                      >
                        {() => (
                          <TextInput error={errors.phone && touched.phone} />
                        )}
                      </InputMask>
                      {errors.phone && touched.phone && (
                        <FieldError>{errors.phone}</FieldError>
                      )}
                    </FormControl>
                  </Field>
                  <Field label="Почта">
                    <FormControl fullWidth>
                      <TextInput
                        name="email"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email && touched.email}
                      />
                    </FormControl>
                    {errors.email && touched.email && (
                      <FieldError>{errors.email}</FieldError>
                    )}
                  </Field>
                  <Field label="Телеграм">
                    <FormControl fullWidth>
                      <TextInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telegram}
                        name="telegram"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">@</InputAdornment>
                          ),
                        }}
                        error={errors.telegram && touched.telegram}
                      />
                    </FormControl>
                    {errors.telegram && touched.telegram && (
                      <FieldError>{errors.telegram}</FieldError>
                    )}
                  </Field>
                </div>
                <button
                  style={{ display: 'none' }}
                  type="submit"
                  ref={buttonRef}
                ></button>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions className={styles.footer}>
          <AppButton color="primary" onClick={addResident}>
            Добавить резидента
          </AppButton>
        </DialogActions>
      </Dialog>
      {showCloseConfirmation && (
        <CloseConfirmation
          title="Добавление резидента"
          onClose={() => setShowCloseConfirmation(false)}
          onClear={() => {
            setShowCloseConfirmation(false);
            back();
          }}
          onSave={confirmSaving}
        />
      )}
      {isSuccess && (
        <SuccessModal
          onClose={returnToResidents}
          description={`
                Резидент добавлен в вашу базу. Чтобы он мог начать пользоваться
                сервисами платформы, направьте ему приглашение для регистрации.`}
          footer={
            <Link to={`/club/invite/${createdCardId}`}>
              <AppButton color="primary" variant="contained">
                Пригласить на платформу
              </AppButton>
            </Link>
          }
        />
      )}
    </>
  );
};
