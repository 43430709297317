import styles from './MemberModal.module.scss';
import { IconButton, FormControl } from '@mui/material';
import { Close } from '@mui/icons-material';
import { AppButton } from '../../../../../components/ui/AppButton/AppButton';
import { AppSelect } from '../../../../../components/ui/AppSelect/AppSelect';
import { api } from '@lib/api';
import { useState, useEffect } from 'react';
import { ResidentAvatar } from '../../../../club/components/ResidentAvatar/ResidentAvatar';
import { startProgress } from '../../../../../store/common';
import { useDispatch } from 'react-redux';
import { AppInput } from '../../../../../components/ui/AppInput/AppInput';
import { CheckBox } from '../../../../../components/ui/CheckBox/CheckBox';
import { FieldError } from '../../FieldError/FieldError';
import { SelectCommunityMembers } from '../../../../../components/ui/SelectCommunityMembers/SelectCommunityMembers';

export const MemberModal = ({ exclude, member, onClose, onAdd }) => {
  const [defaultCards, setDefaultCards] = useState();
  const [card, setCard] = useState(null);
  const [description, setDescription] = useState(null);
  const [showTelegram, setShowTelegram] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [isInit, setIsInit] = useState(() => !member);
  const dispatch = useDispatch();
  const [touched, setTouched] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  let error = null;
  if (description) {
    if (description.length < 2) {
      error = 'Минимальное количество символов - 2';
    } else if (description.length > 80) {
      error = 'Максимальное количество символов - 80'
    }
  }

  const addMember = () => {
    setFormSubmitted(true);
    if (!card || error) return;
    const data = {
      cardId: card.value.id,
      description,
      showTelegram,
      telegram: member?.telegram || card.value.telegram,
      firstName: card.value.firstName,
      lastName: card.value.lastName,
      avatar: card.value.avatar,
    };
    if (!data.description) {
      delete data.description;
    }
    onAdd(data);
  };

  const init = async () => {
    if (member) {
      dispatch(startProgress());
      setDescription(member.description);
      setShowTelegram(!!member.showTelegram);
      setIsInit(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const isChanged = member && !(defaultValue?.value?.id === member.cardId && description === member.description && showTelegram == member.showTelegram);

  const toggleShowTelegram = () => {
    setShowTelegram(prev => !prev);
  };

  const isButtonDisabled = member ? !isChanged : !card;

  if (!isInit) return null;

  return <div className={styles.member}>
    <div className={styles.header}>
      <div className={styles.title}>{member ? `Редактирование члена команды` : 'Новый член команды'}</div>
      <div className={styles.close}>
        <IconButton onClick={onClose}><Close /></IconButton>
      </div>
    </div>
    <div className={styles.content}>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>Участник</div>
        <div className={styles.sectionContent}>
          <SelectCommunityMembers
            cardId={member?.cardId}
            value={defaultValue || card}
            exclude={exclude}
            onChange={value => {
              setCard(value);
              setDefaultValue(value);
            }}
            defaultCards={defaultCards} />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>Краткое описание</div>
        <div className={styles.sectionContent}>
          <FormControl fullWidth>
            <AppInput value={description} multiline rows={2} onChange={ev => setDescription(ev.target.value)} placeholder="Расскажите о его деятельности" onBlur={() => setTouched(true)} error={(touched || formSubmitted) && error} />
          </FormControl>
          {(touched || formSubmitted) && error && (
            <FieldError>{error}</FieldError>
          )}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionHeader}></div>
        <div className={styles.sectionContent + ' ' + styles.telegram} onClick={toggleShowTelegram}>
          <div className={styles.checkbox}>
            <CheckBox checked={!!showTelegram} />
          </div>
          <div className={styles.label}>Отображать Telegram участника</div>
        </div>
      </div>
    </div>
    <div className={styles.footer}>
      <AppButton color="secondary" onClick={onClose}>Отмена</AppButton>
      &nbsp;
      <AppButton onClick={addMember} disabled={isButtonDisabled || ((touched || formSubmitted) && error)}>{member ? 'Сохранить' : 'Добавить'}</AppButton>
    </div>
  </div>
};
